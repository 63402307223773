import { Button, Card, ThemeProvider } from '@aws-amplify/ui-react';
import { useCallback, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { zptheme } from '../../theme';


const contentStyle = { background: 'transparent', maxHeight: '100vh', maxWidth: '100vw' }
const overlayStyle = { background: 'rgba(0,0,0,0.5)' }


function SimpleModal({ confirmAction, dismissAction, open, children, style, okBtnText, cancelBtnText, idName, confirmBtnDisabled, hideCancelBtn }: { confirmAction?: any, dismissAction: any, open: boolean, children?: any, style?: any, okBtnText?: string, cancelBtnText?: string, idName?: string, confirmBtnDisabled?: boolean, hideCancelBtn?: Boolean }) {

    const keyDownFunction = useCallback((event: KeyboardEvent) => {
        if (event.key === "Enter" && open) {
            confirmAction()
        }
        if (event.key === "Escape" && open) {
            dismissAction()
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", keyDownFunction, false);

        return () => {
            document.removeEventListener("keydown", keyDownFunction, false);
        };
    }, []);

    return (
        <ThemeProvider theme={zptheme}>
            <Popup
                open={open}
                position='center center'
                closeOnDocumentClick
                onClose={() => dismissAction()}
                modal={true}
                {...{ contentStyle, overlayStyle }}
            >
                <ThemeProvider theme={zptheme}>
                    <Card style={{ width: '25rem', borderRadius: '0.5em', ...style }}
                    id={idName ? `${ idName }-modal` : 'simple-modal'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                    >
                        <div style={{ marginTop: '1em' }}>
                            {children}
                        </div>
                        <div style={{ height: 20 }} />
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                style={{ marginRight: '1rem' }}
                                size='small'
                                variation='primary'
                                isDisabled={confirmBtnDisabled || false}
                                onClick={() => {
                                    if (confirmAction) {
                                        confirmAction();
                                    }
                                } } placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {okBtnText ? okBtnText : 'OK'}
                            </Button>
                            {!hideCancelBtn && <Button
                                size='small'
                                variation='primary'
                                onClick={dismissAction} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {cancelBtnText ? cancelBtnText : 'Abbrechen'}
                            </Button>}
                        </div>
                    </Card>
                </ThemeProvider>

            </Popup>
        </ThemeProvider>
    )
}

export default SimpleModal;