import { ProductStatus, Product, OrderSource, Order, Shipment, OrderState } from '../API';
import { Color } from '../theme';
import { MdCancel, MdFiberNew, MdOutlineLockClock } from 'react-icons/md'
import { IoHammerSharp } from 'react-icons/io5';
import { GoPackage } from 'react-icons/go';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { BsExclamationOctagonFill } from 'react-icons/bs'
import { AiOutlineEuro } from 'react-icons/ai';
import { TbTruckReturn } from 'react-icons/tb'
import { getArticleNumber } from './contractor-articel-numbers';
import { taskNames } from './Constants';

export function prettyDate(date: String) {
    if (!date) return ''
    const [year, month, day] = date.split('T')[0].split('-')
    return `${ day }.${ month }.${ year }`;
}

export function dayOfDate(date: string) {
    const dayNumber = new Date(date).getDay()
    switch (dayNumber) {
        case 0:
            return 'Sonntag'
        case 1:
            return 'Montag'
        case 2:
            return 'Dienstag'
        case 3:
            return 'Mittwoch'
        case 4:
            return 'Donnerstag'
        case 5:
            return 'Freitag'
        case 6:
            return 'Samstag'
        default:
            return ''
    }
}

export function statusName(status: String | null | undefined) {
    switch (status) {
        case OrderState.importing:
            return 'Lade Positionen...'
         case ProductStatus.new:
            return 'Neu'
        case OrderState.finished:
        case ProductStatus.packing_confirmed:
        case ProductStatus.packing_confirmed:
            return 'Fertig gestellt'
        case ProductStatus.in_progress:
        case OrderState.unfinished:
            return 'In Arbeit'
        case ProductStatus.ready_to_pack:
            return 'Packbereit'
        case ProductStatus.cancelled:
            return 'Storniert'
        case ProductStatus.pending_return:
            return 'Retoure ausstehend'
        case ProductStatus.billed:
            return 'Abgerechnet'
        default:
            return 'Neu'
    }
}

export function statusColor(status: String | null | undefined) {
    switch (status) {
        case OrderState.importing:
            return Color.darkgrey
        case ProductStatus.new:
        case ProductStatus.pending_return:
            return Color.orange
        case ProductStatus.packing_confirmed:
            return Color.blue
        case ProductStatus.billed:
            return Color.lightblue;
        case ProductStatus.in_progress:
        case OrderState.unfinished:
            return Color.lightblue
        case ProductStatus.cancelled:
            return Color.red
        default:
            return Color.orange
    }
}

export function statusIcon(status: string | null | undefined) {
    switch (status) {
        case ProductStatus.pending_return:
            return < TbTruckReturn style={{ color: statusColor(status) }} />
        case OrderState.importing:
            return <MdOutlineLockClock id='locked-icon' />
        case ProductStatus.new:
            return <MdFiberNew style={{ color: statusColor(status) }} id='new-icon' />
        case ProductStatus.in_progress:
        case OrderState.unfinished:
            return <IoHammerSharp style={{ color: statusColor(status) }} id='hammer-icon' />
        case ProductStatus.ready_to_pack:
            return <GoPackage style={{ color: statusColor(status) }} id='package-icon' />
        case ProductStatus.packing_confirmed:
        case OrderState.finished:
            return <IoMdCheckmarkCircleOutline style={{ color: statusColor(status) }} id='checked-circle-icon' />
        case ProductStatus.cancelled:
            return <MdCancel style={{ color: statusColor(status) }} id='cancel-icon' />
        case ProductStatus.billed:
            return <AiOutlineEuro style={{ color: statusColor(status) }} id='euro-icon' />
        default:
            return <MdFiberNew style={{ color: Color.orange }} id='new-icon' />
    }
}

export function prettyPrice(price: number) {
    if (price) {
        return price.toFixed(2)
    }
}

export function prettyWeight(product: Product) {
    const weight = product.weight ? parseFloat(product.weight) : 0
    const quantity = product.quantity ? product.quantity : 1
    const totalWeight = weight * quantity

    return totalWeight.toFixed(2)
}

export function exportOrdersToCSV(items: Product[], status: String) {
    const tableData = [
        ['Nummer', 'Status', 'Fertiger', 'Menge', 'Material', 'Artikelnummer', 'Länge', 'Breite', 'Optionen', 'Tätigkeiten', 'Kommentar']
    ]
    const dateString = new Date().toISOString().split('.')[0]
    const fileName = `Aufträge-${ status.replace(' ', '-') }-${ dateString }`

    for (const item of items) {
        const articelNumber = item.contractor?.number ? getArticleNumber(item.variation_id, item.contractor?.number) : item.product_id
        tableData.push([
            item.orderNumber?.toString() || '',
            statusName(item.status),
            item.contractor?.name || '',
            item.quantity?.toString() || '',
            item.name,
            articelNumber || '',
            item.length?.toString() || '',
            item.width?.toString() || '',
            item.extraOptions && item.extraOptions.length > 0 ? item.extraOptions.map(o => o?.name ? `${ o.name }: ${ o.value }` : o?.value).join(', ') : '',
            item.tasks ? item.tasks?.toString() : '',
            item.backoffice_note || ''
        ])
    }
    let csvContent = "data:text/csv;charset=utf-8,"
        + tableData.map(e => e.join(";")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + '.csv');
    document.body.appendChild(link); // Required for FF

    link.click();
}

export function getDeliveryTimeInDays(deliveryTime: string) {
    var regEx = /\d+/g;
    var m;
    var numberArray = []
    while ((m = regEx.exec(deliveryTime)) != null) {
        numberArray.push(parseFloat(m[0]))
    }
    var max = Math.max(...numberArray)
    return max
}

export function calculateDeliveryDate(datePaid: string, deliveryTime: number, shippingMethod?: string | null) {
    let date = new Date(datePaid)
    if (deliveryTime) {
        date.setDate(date.getDate() + deliveryTime)
    }
    if (shippingMethod && shippingMethod.includes('Express')) {
        var regEx = /\d+/;
        const days = shippingMethod.match(regEx);
        //@ts-ignore
        if (days) date.setDate(date.getDate() - days)
    }
    return date.toISOString()
}

export function orderSourceName(source: OrderSource) {
    switch (source) {
        case OrderSource.woocommerce:
            return 'WooCommerce'
        case OrderSource.amazon:
            return 'Amazon'
        case OrderSource.etsy:
            return 'Etsy'
        case OrderSource.rekla:
            return 'Reklamation'
        default:
            return source
    }
}

export function compareTaskList() {
    const order = [
        taskNames.ZUSCHNEIDEN.name,
        taskNames.ENTGRATEN.name,
        taskNames.SAEGEN.name,
        taskNames.ABKANTEN.name,
        taskNames.ASTLOECHER_BEIDSEITIG.name,
        taskNames.ASTLOECHER_EINSEITIG.name,
        taskNames.BOHREN.name,
        taskNames.ECKEN_ABRUNDEN.name,
        taskNames.FRAESEN.name,
        taskNames.SCHWEISSEN.name,
        taskNames.BAUMKANTE.name,
        taskNames.SCHLEIFEN.name,
        taskNames.KANTEN_ABRUNDEN.name,
        taskNames.FASEN.name,
        taskNames.KANTEN_BRECHEN.name,
        taskNames.BUERSTEN.name,
        taskNames.OELEN.name,
        taskNames.PULVERN.name]
    return ((a: string | null, b: string | null) => ((a ? order.indexOf(a) : 0) - (b ? order.indexOf(b) : 0)))
}
export function getNextTask(product: Product) {
    // '' means nothing to do
    if (!product || !product.tasks || product.tasks.length === 0) return ''

    const sortedTasks = [...product.tasks].sort(compareTaskList())

    if (!product.currentTask || product.currentTask === 'printLabel') {
        // the Button to set the next task only appears if the item is in status 'in_progress' so it makes sense to return the first task if there is no current task
        return sortedTasks[0]
    }
    const currentTaskIndex = sortedTasks.indexOf(product.currentTask)

    if (currentTaskIndex === sortedTasks.length - 1) {
        // tasks all done
        return ''
    }
    return sortedTasks[currentTaskIndex + 1]
}

export function capitalizeFirstLetter(sentence: string) {
    return sentence.split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}

export async function rePrintLabel({ shipment, order }: { shipment: Shipment, order: Order }) {
    if (!shipment.labelData) return

    const linkSource = `data:application/${ shipment.labelData?.format };base64,${ shipment.labelData?.base64 }`;
    const downloadLink = document.createElement("a");
    const fileName = `Retoure-${ order.orderNumber }-${ order.customer.first_name }-${ order.customer.last_name }-${ shipment.parcelNumber }.${ shipment.labelData?.format }`.replace(/ /g, '-');

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export const finishedItem = (item: Product) => item.status === ProductStatus.billed || item.status === ProductStatus.cancelled || item.status === ProductStatus.packing_confirmed
export const onGoingItemBeforePacking = (item: Product) => item.status === ProductStatus.new || item.status === ProductStatus.in_progress
export const readyToPackItem = (item: Product) => item.status === ProductStatus.ready_to_pack 