import { useEffect, useState } from 'react';
import SimpleModal from '../helper-components/SimpleModal';
import { Alert, Button, CheckboxField, Heading, TextField } from '@aws-amplify/ui-react';
import { useAppDispatch } from '../../store/hooks';
import { emailRegex, numbersChar } from '../../utils/Constants';
import { HiPencil } from 'react-icons/hi';
import { setItemsInfo } from '../../store/itemsSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { FaRegAddressBook } from 'react-icons/fa';
import { updateContractorAsync } from '../../store/configSlice';
import { IoInformationCircleOutline } from 'react-icons/io5';
import ToolTipPopup from '../helper-components/ToolTipPopup';

function ContractorAddressPopup({ contractorID }: { contractorID: string }) {
    const dispatch = useAppDispatch()
    const contractor = useSelector((state: RootState) => state.configState.contractors.find(c => c.id === contractorID))
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [showReturnAddressInputFields, setShowReturnAddressInputFields] = useState(false)

    const [name, setName] = useState<string | null | undefined>(undefined)
    const [street, SetStreet] = useState<string | null | undefined>(undefined)
    const [houseNo, SetHouseNo] = useState<string | null | undefined>(undefined)
    const [city, SetCity] = useState<string | null | undefined>(undefined)
    const [zip, SetZip] = useState<string | null | undefined>(undefined)
    const [email, SetEmail] = useState<string | null | undefined>(undefined)
    const [phone, setPhone] = useState<string | null | undefined>(undefined)

    const [returnName, setReturnName] = useState<string | null | undefined>(undefined)
    const [returnStreet, setReturnStreet] = useState<string | null | undefined>(undefined)
    const [returnHouseNo, setReturnHouseNo] = useState<string | null | undefined>(undefined)
    const [returnCity, setReturnCity] = useState<string | null | undefined>(undefined)
    const [returnZip, setReturnZip] = useState<string | null | undefined>(undefined)
    const [returnEmail, setReturnEmail] = useState<string | null | undefined>(undefined)
    const [returnPhone, setReturnPhone] = useState<string | null | undefined>(undefined)

    const [errorMessage, setErrorMessage] = useState('')
    const [infoMessage, setInfoMessage] = useState('')

    useEffect(() => {
        if (edit) {
            setName(contractor?.pickupAddress?.name)
            SetStreet(contractor?.pickupAddress?.street)
            SetHouseNo(contractor?.pickupAddress?.house_number)
            SetCity(contractor?.pickupAddress?.city)
            SetZip(contractor?.pickupAddress?.zip)
            SetEmail(contractor?.pickupAddress?.email)
            setPhone(contractor?.pickupAddress?.phone)
            if (contractor?.returnAddress) {
                setShowReturnAddressInputFields(true)
                setReturnName(contractor?.returnAddress?.name)
                setReturnStreet(contractor?.returnAddress?.street)
                setReturnHouseNo(contractor?.returnAddress?.house_number)
                setReturnCity(contractor?.returnAddress?.city)
                setReturnZip(contractor?.returnAddress?.zip)
                setReturnEmail(contractor?.returnAddress?.email)
                setReturnPhone(contractor?.returnAddress?.phone)
            }
        }
        return () => {
            setName(undefined)
            SetStreet(undefined)
            SetHouseNo(undefined)
            SetCity(undefined)
            SetZip(undefined)
            SetEmail(undefined)
            setPhone(undefined)
            setShowReturnAddressInputFields(false)
            setReturnName(undefined)
            setReturnStreet(undefined)
            setReturnHouseNo(undefined)
            setReturnCity(undefined)
            setReturnZip(undefined)
            setReturnEmail(undefined)
            setReturnPhone(undefined)
            setInfoMessage('')
            setErrorMessage('')
        }
    }, [edit])

    function close() {
        setOpen(false)
        setEdit(false)
        setInfoMessage('')
        setErrorMessage('')
    }

    async function copyAdress() {
        let clipboardText = `
        Firmenname: ${ contractor?.pickupAddress?.name }
        Straße + Nr.: ${ contractor?.pickupAddress?.street } ${ contractor?.pickupAddress?.house_number }
        PLZ + Ort: ${ contractor?.pickupAddress?.zip } ${ contractor?.pickupAddress?.city }
        E-Mail: ${ contractor?.pickupAddress?.email }
        Telefon: ${ contractor?.pickupAddress?.phone }
        `
        if (contractor?.returnAddress) {
            clipboardText += `
        Abweichende Absender Adresse:
        Firmenname: ${ contractor?.returnAddress?.name }
        Straße + Nr.: ${ contractor?.returnAddress?.street } ${ contractor?.returnAddress?.house_number }
        PLZ + Ort: ${ contractor?.returnAddress?.zip } ${ contractor?.returnAddress?.city }
        E-Mail: ${ contractor?.returnAddress?.email }
        Telefon: ${ contractor?.returnAddress?.phone }
       `
        }
        await navigator.clipboard.writeText(clipboardText)
        setOpen(false)
        alert('Adresse wurde in die Zwischenablage kopiert')
    }

    async function saveChangedAdress() {
        setErrorMessage('')
        if (!contractor) return
        const variables = {
            input: {
                id: contractor.id,
                pickupAddress: {
                    name: name,
                    street: street,
                    house_number: houseNo,
                    city: city,
                    zip: zip,
                    email: email,
                    phone: phone,
                    // it is needed for the shipping service providers but it is always DE
                    country: 'DE',
                },
                returnAddress: showReturnAddressInputFields ? {
                    name: returnName,
                    street: returnStreet || '',
                    house_number: returnHouseNo || '',
                    city: returnCity || '',
                    zip: returnZip || '',
                    email: returnEmail || '',
                    phone: returnPhone || '',
                    // it is needed for the shipping service providers but it is always DE
                    country: 'DE',
                } : null
            }
        }
        const updateContractorAction = await dispatch(updateContractorAsync(variables))
        if (updateContractorAction.meta.requestStatus === 'fulfilled') {
            dispatch(setItemsInfo('Adresse wurde erfolgreich geändert'))
        }
        close()
    }

    function ReturnAddressToolTip() {
        return (
            <ToolTipPopup
                trigger={<IoInformationCircleOutline />}
                tipText={'Die Absender Adresse steht auf dem Versandlabel als Absender und muss nicht der Abholort sein.'} />
        )
    }

    if (edit) return (
        <div>
            <SimpleModal dismissAction={close} confirmAction={saveChangedAdress} open={edit} style={{ width: '60vw' }}>
                {errorMessage && <div>
                    <Alert
                        variation='error'
                        isDismissible={false}
                        placeholder={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined} >
                        {errorMessage}
                    </Alert>
                    <div style={{ height: '1em' }} />
                </div>}
                <div style={{ display: 'grid', gap: '0.5em' }}>
                    <Heading placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} style={{ textAlign: 'center' }}>
                        <div style={{ flex: 2 }}>{contractor?.number} - {contractor?.name}</div>
                    </Heading>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>Firmenname</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'name'} defaultValue={name || ''} labelHidden={true}
                                onChange={(e: any) => { setName(e.target.value); }}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                            Straße + Nr.</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 1 }}>
                                <TextField label={'street'} defaultValue={street || ''} labelHidden={true}
                                    onChange={(e: any) => { SetStreet(e.target.value) }}
                                />
                            </div>
                            <div style={{ width: 2 }} />
                            <div style={{ flex: 1 }}>
                                <TextField label={'houseNo'} defaultValue={houseNo || ''} labelHidden={true}
                                    onChange={(e: any) => { SetHouseNo(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                            PLZ + Ort</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 1 }}>
                                <TextField label={'postcode'} defaultValue={zip || ''} labelHidden={true}
                                    onChange={(e: any) => { SetZip(e.target.value) }}
                                    hasError={zip && !numbersChar.test(zip) ? true : false}
                                />
                            </div>
                            <div style={{ width: 2 }} />
                            <div style={{ flex: 1 }}>
                                <TextField label={'city'} defaultValue={city || ''} labelHidden={true}
                                    onChange={(e: any) => { SetCity(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >E-Mail</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'email'} defaultValue={email || ''} labelHidden={true}
                                onChange={(e: any) => { SetEmail(e.target.value) }}
                                hasError={email && !emailRegex.test(email) ? true : false}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Telefon</div>
                        <div style={{ flex: 2 }}>
                            <TextField label={'phone'} defaultValue={phone || ''} labelHidden={true}
                                onChange={(e: any) => { setPhone(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ height: '1em' }} />
                <div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
                <CheckboxField
                    label={'abweichende Absender Adresse'}
                    name={'diifferentReturnAddress'}
                    checked={showReturnAddressInputFields ? true : false}
                    onChange={(e) => {
                        setShowReturnAddressInputFields(!showReturnAddressInputFields)
                        if (!showReturnAddressInputFields) {
                            setReturnName('')
                            setReturnStreet('')
                            setReturnHouseNo('')
                            setReturnCity('')
                            setReturnZip('')
                            setReturnEmail('')
                            setReturnPhone('')
                        }
                    }}
                    crossOrigin={undefined}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                    value={''} />
                    {ReturnAddressToolTip()}
                </div>
                <div style={{ height: '0.5em' }} />
                {/* return address input field */}
                {showReturnAddressInputFields &&
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>Firmenname</div>
                            <div style={{ flex: 2 }}>
                                <TextField label={'returnName'} defaultValue={returnName || ''} labelHidden={true}
                                    onChange={(e: any) => { setReturnName(e.target.value); }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                                Straße + Nr.</div>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                                <div style={{ flex: 1 }}>
                                    <TextField label={'returnStreet'} defaultValue={returnStreet || ''} labelHidden={true}
                                        onChange={(e: any) => { setReturnStreet(e.target.value) }}
                                    />
                                </div>
                                <div style={{ width: 2 }} />
                                <div style={{ flex: 1 }}>
                                    <TextField label={'returnHouseNo'} defaultValue={returnHouseNo || ''} labelHidden={true}
                                        onChange={(e: any) => { setReturnHouseNo(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }}>
                                PLZ + Ort</div>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                                <div style={{ flex: 1 }}>
                                    <TextField label={'returnPostcode'} defaultValue={returnZip || ''} labelHidden={true}
                                        onChange={(e: any) => { setReturnZip(e.target.value) }}
                                        hasError={returnZip && !numbersChar.test(returnZip) ? true : false}
                                    />
                                </div>
                                <div style={{ width: 2 }} />
                                <div style={{ flex: 1 }}>
                                    <TextField label={'returnCity'} defaultValue={returnCity || ''} labelHidden={true}
                                        onChange={(e: any) => { setReturnCity(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >E-Mail</div>
                            <div style={{ flex: 2 }}>
                                <TextField label={'returnEmail'} defaultValue={returnEmail || ''} labelHidden={true}
                                    onChange={(e: any) => { setReturnEmail(e.target.value) }}
                                    hasError={returnEmail && !emailRegex.test(returnEmail) ? true : false}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ marginRight: '5em', paddingTop: '1em', flex: 1 }} >Telefon</div>
                            <div style={{ flex: 2 }}>
                                <TextField label={'returnPhone'} defaultValue={returnPhone || ''} labelHidden={true}
                                    onChange={(e: any) => { setReturnPhone(e.target.value) }}
                                />
                            </div>
                        </div>
                    </div>}
            </SimpleModal>
        </div>
    )

    return (
        <div>
            <Button
                size='small'
                variation='link'
                onClick={() => setOpen(true)}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}>
                <FaRegAddressBook />
            </Button>
            <SimpleModal dismissAction={close} open={open} okBtnText='Kopieren' cancelBtnText='Schließen' confirmAction={copyAdress}>
                {infoMessage && <Alert
                    fontSize={'small'}
                    style={{ marginBottom: '1em' }}
                    variation='info' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                >
                    {infoMessage}
                </Alert>}
                <div style={{ display: 'flex' }}>
                    <Heading style={{ flex: 1, textAlign: 'center' }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <div style={{ flex: 2 }}>{contractor?.number} - {contractor?.name}</div>
                    </Heading>
                    <Button
                        id='edit-contractor-address-button'
                        style={{ backgroundColor: 'transparent', padding: '0' }}
                        size={'small'}
                        variation='link'
                        onClick={() => { setEdit(true); setOpen(false); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                        <HiPencil />
                    </Button>
                </div>
                <div style={{ display: 'grid', gap: '0.5em' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', flex: 1 }}>Firmenname:</div>
                        <div style={{ flex: 2 }}>{contractor?.pickupAddress?.name}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '5em', flex: 1 }}>
                            Straße + Nr.:</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 2 }}>{contractor?.pickupAddress?.street} {contractor?.pickupAddress?.house_number}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }}>
                            PLZ + Ort:</div>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                            <div style={{ flex: 2 }}>{contractor?.pickupAddress?.zip} {contractor?.pickupAddress?.city}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }} >E-Mail:</div>
                        <div style={{ flex: 2 }}>{contractor?.pickupAddress?.email}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }} >
                        <div style={{ marginRight: '5em', flex: 1 }} >Telefon:</div>
                        <div style={{ flex: 2 }}>{contractor?.pickupAddress?.phone}</div>
                    </div>
                </div>
                {contractor?.returnAddress &&
                    <div style={{ display: 'grid', gap: '0.5em', marginTop: '1em' }}>
                        <div style={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
                            <div style={{ fontWeight: 'bold' }}>Absender Adresse:</div>
                            {ReturnAddressToolTip()}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '5em', flex: 1 }}>Firmenname:</div>
                            <div style={{ flex: 2 }}>{contractor?.returnAddress?.name}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ marginRight: '5em', flex: 1 }}>
                                Straße + Nr.:</div>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                                <div style={{ flex: 2 }}>{contractor?.returnAddress?.street} {contractor?.returnAddress?.house_number}</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ marginRight: '5em', flex: 1 }}>
                                PLZ + Ort:</div>
                            <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
                                <div style={{ flex: 2 }}>{contractor?.returnAddress?.zip} {contractor?.returnAddress?.city}</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ marginRight: '5em', flex: 1 }} >E-Mail:</div>
                            <div style={{ flex: 2 }}>{contractor?.returnAddress?.email}</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <div style={{ marginRight: '5em', flex: 1 }} >Telefon:</div>
                            <div style={{ flex: 2 }}>{contractor?.returnAddress?.phone}</div>
                        </div>
                    </div>}
            </SimpleModal>
        </div>
    )
}

export default ContractorAddressPopup;