import Drawing from 'dxf-writer'
import { Product } from '../API';

export function createDXF(item: Product) {
    if (!item.length || !item.width) return
    let length = item.length * 10
    let width = item.width * 10
    const strengthString = item.variations?.find(v => v?.includes('Stärke'))?.split(' ')[1]
    const strength = parseFloat(strengthString?.replace('mm', '').replace(',', '.') || '1')
    const kantAbzug = 2 * strength
    const cornerRadius = item.extraOptions?.find(o => o?.value?.includes('Ecken abrunden')) ? 10 : 0;

    const material = item.name?.split(' - ')[0].replaceAll(' ', '');
    let filename = `${ item.orderNumber }-${ item.position || 1 }-${ item.quantity }Stk-${ material }-${ strengthString }`

    const KUOption = item.extraOptions?.find(o => o?.name?.includes('KU Außenmaß'))?.value;
    const KOOption = item.extraOptions?.find(o => o?.name?.includes('KO Außenmaß'))?.value;
    const KLOption = item.extraOptions?.find(o => o?.name?.includes('KL Außenmaß'))?.value;
    const KROption = item.extraOptions?.find(o => o?.name?.includes('KR Außenmaß'))?.value;
    let bottomK = (KUOption ? parseFloat(KUOption) : 0) * 10; // Bottom folding
    let topK = (KOOption ? parseFloat(KOOption) : 0) * 10; // Top folding
    let leftK = (KLOption ? parseFloat(KLOption) : 0) * 10; // Left folding
    let rightK = (KROption ? parseFloat(KROption) : 0) * 10; // Right folding

    // Adjust length and width based on foldings because the material will expand while folding
    // width based on foldings on the bottom and top sides
    if (bottomK > 0) {
        width -= kantAbzug;
    }
    if (topK > 0) {
        width -= kantAbzug;
    }
    // length based on foldings on the left and right sides
    if (leftK > 0) {
        length -= kantAbzug;
    }
    if (rightK > 0) {
        length -= kantAbzug;
    }

    const d = new Drawing();

    // set units and add layer for the cutting
    d.setUnits('Millimeters');
    d.addLayer('zuschnitt', 0, 'CONTINUOUS');
    d.setActiveLayer('zuschnitt');

    const leftLineStart = { x: 0, y: cornerRadius };
    const leftLineEnd = { x: 0, y: width - cornerRadius };
    const rightLineStart = { x: length, y: cornerRadius };
    const rightLineEnd = { x: length, y: width - cornerRadius };
    const topLineStart = { x: cornerRadius, y: width };
    const topLineEnd = { x: length - cornerRadius, y: width };
    const bottomLineStart = { x: cornerRadius, y: 0 };
    const bottomLineEnd = { x: length - cornerRadius, y: 0 };

    // Handle cutouts for adjacent foldings
    const radiusFreistich = 1.1 * strength
    if (bottomK > 0 && leftK > 0) {
        // Cutout at bottom-left corner
        d.drawLine(leftK, 0, leftK, bottomK - radiusFreistich); // Right line of the cutout
        d.drawLine(0, bottomK, leftK - radiusFreistich, bottomK); // Top line of the cutout
        // draw Arc to the center of the plate
        d.drawArc(leftK, bottomK, radiusFreistich, -90, 180);
        leftLineStart.y = bottomK;
        bottomLineStart.x = leftK;
    }

    if (bottomK > 0 && rightK > 0) {
        // Cutout at bottom-right corner
        d.drawLine(length - rightK, 0, length - rightK, bottomK - radiusFreistich); // Left line of the cutout
        d.drawLine(length - rightK + radiusFreistich, bottomK, length, bottomK); // Top line of the cutout
        // draw Arc to the center of the plate
        d.drawArc(length - rightK, bottomK, radiusFreistich, 0, 270);
        // shorten the bottem line at the right side and let the right line start at the end of the cutout
        rightLineStart.y = bottomK;
        bottomLineEnd.x = length - rightK;
    }

    if (topK > 0 && leftK > 0) {
        // Cutout at top-left corner
        d.drawLine(leftK, width - topK + radiusFreistich, leftK, width); // Right line of the cutout
        d.drawLine(0, width - topK, leftK - radiusFreistich, width - topK); // Bottom line of the cutout
        d.drawArc(leftK, width - topK, radiusFreistich, 180, 90);
        leftLineEnd.y = width - topK;
        topLineStart.x = leftK;
    }

    if (topK > 0 && rightK > 0) {
        // Cutout at top-right corner
        d.drawLine(length - rightK, width - topK + radiusFreistich, length - rightK, width); // Left line of the cutout
        d.drawLine(length - rightK + radiusFreistich, width - topK, length, width - topK); // Bottom line of the cutout
        d.drawArc(length - rightK, width - topK, radiusFreistich, 90, 0);
        // Adjust the end of the top line and shorten the right line
        rightLineEnd.y = width - topK;
        topLineEnd.x = length - rightK;
    }

    // Draw lines for a rectangle
    d.drawLine(leftLineStart.x, leftLineStart.y, leftLineEnd.x, leftLineEnd.y); // Left line
    d.drawLine(bottomLineStart.x, bottomLineStart.y, bottomLineEnd.x, bottomLineEnd.y); // Bottom line
    d.drawLine(rightLineStart.x, rightLineStart.y, rightLineEnd.x, rightLineEnd.y); // Right line
    d.drawLine(topLineStart.x, topLineStart.y, topLineEnd.x, topLineEnd.y); // Top line

    if (item.extraOptions?.find(el => el?.value.includes('Schliff an der Breite entlang'))) {
        filename += '-SchliffBreite'
    }

    if (item.extraOptions?.find(el => el?.value.includes('Schliff an der Länge entlang'))) {
        filename += '-SchliffLaenge'
    }


    // Draw arcs if corners are rounded
    // Draw arcs if corners are rounded (after adjusting main lines)
    if (cornerRadius > 0) {
        if (!(bottomK > 0 && leftK > 0)) {
            d.drawArc(cornerRadius, cornerRadius, cornerRadius, 180, 270); // Bottom left
        }
        if (!(bottomK > 0 && rightK > 0)) {
            d.drawArc(length - cornerRadius, cornerRadius, cornerRadius, 270, 0); // Bottom right
        }
        if (!(topK > 0 && leftK > 0)) {
            d.drawArc(cornerRadius, width - cornerRadius, cornerRadius, 90, 180); // Top left
        }
        if (!(topK > 0 && rightK > 0)) {
            d.drawArc(length - cornerRadius, width - cornerRadius, cornerRadius, 0, 90); // Top right
        }
    }

    // Add drill holes with Standardabmessungen
    const drillHolesCountOption = item.extraOptions?.find(o => o?.name?.includes('Wieviele Bohrungen'))?.value || '0';
    if (drillHolesCountOption !== '0') {
        const drillHolesCount = parseInt(drillHolesCountOption);
        const screwSizeOption = item.extraOptions?.find(o => o?.name?.includes('Bohrlochdurchmesser'))?.value;
        const screwSize = screwSizeOption?.match(/\d+/)?.[0] || '0';
        const holeOffsetOption = item.extraOptions?.find(o => o?.name?.includes('Bohrungsabstand vom Rand'))?.value?.includes('Standard') ?
            '1.5' :
            item.extraOptions?.find(o => o?.name?.includes('Abstand vom Rand in cm'))?.value;
        // find the first float number in the string
        const holeOffsetString = holeOffsetOption?.match(/[+-]?\d+(?:[\.]\d+)?/)?.[0] || '0';
        const baseSideOptions = item.extraOptions?.find(o => o?.name?.includes('Bohrung an welcher Seite?'))?.value;

        const baseSide = baseSideOptions?.includes('Breite') ? 'Breite' : 'Länge';
        const holeRadius = (parseFloat(screwSize) + 0.5) / 2;
        const holeOffset = parseFloat(holeOffsetString) * 10; // distance from the edge in mm
        const drawCircles = []

        if (drillHolesCount !== 2 && drillHolesCount !== 4 && drillHolesCount !== 6) {
            return { message: item.orderNumber + ': Bohrungen können nur in 2er, 4er oder 6er Schritten erstellt werden' };
        }
        if (holeRadius === 0) {
            return { message: item.orderNumber + ': Bohrungsdurchmesser muss eine Zahl enthalten' };
        }
        if (holeOffset === 0) {
            return { message: item.orderNumber + ': Bohrungsabstand vom Rand muss eine Zahl enthalten' };

        }
        // Draw holes
        if (drillHolesCount >= 2) {
            // top left
            drawCircles.push({ x: holeOffset, y: width - holeOffset, r: holeRadius });
            // top right for BaseSide = Länge or default
            if (baseSide === 'Länge') drawCircles.push({ x: length - holeOffset, y: width - holeOffset, r: holeRadius });
            // bottom left for BaseSide = Breite
            if (baseSide === 'Breite') drawCircles.push({ x: holeOffset, y: holeOffset, r: holeRadius });
        }
        if (drillHolesCount >= 4) {
            // bottom right
            drawCircles.push({ x: length - holeOffset, y: holeOffset, r: holeRadius });
            // bottom left for BaseSide = Länge or default
            if (baseSide === 'Länge') drawCircles.push({ x: holeOffset, y: holeOffset, r: holeRadius });
            // top right for BaseSide = Breite
            if (baseSide === 'Breite') drawCircles.push({ x: length - holeOffset, y: width - holeOffset, r: holeRadius });
        }
        if (drillHolesCount >= 6) {
            if (baseSide === 'Länge') {
                // top middle
                drawCircles.push({ x: length / 2, y: width - holeOffset, r: holeRadius });
                // bottom middle
                drawCircles.push({ x: length / 2, y: holeOffset, r: holeRadius });
            }
            if (baseSide === 'Breite') {
                // middle left
                drawCircles.push({ x: holeOffset, y: width / 2, r: holeRadius });
                // middle right
                drawCircles.push({ x: length - holeOffset, y: width / 2, r: holeRadius });
            }
        }
        d.setActiveLayer('zuschnitt');
        drawCircles.map(c => d.drawCircle(c.x, c.y, c.r));
    }
    const individualDrillHoles = item.extraOptions?.find(o => o?.value?.includes('freie Platzierung der Bohrungen'));
    if (individualDrillHoles) {
        const screwSizeOption = item.extraOptions?.find(o => o?.name?.includes('Bohrlochdurchmesser'))?.value;
        const screwSize = screwSizeOption?.match(/\d+/)?.[0] || '0';
        const holeRadius = (parseFloat(screwSize) + 0.5) / 2;
        const drillHoles = item.extraOptions?.filter(o => o?.name?.includes('Bohrlöcher hinzufügen') && o?.value).map(o => o?.value)

        if (!drillHoles || drillHoles.length === 0) {
            return { message: item.orderNumber + ': Maße für Bohrlöcher fehlen' };
        }
        if (holeRadius === 0) {
            return { message: item.orderNumber + ': Bohrungsdurchmesser muss eine Zahl enthalten' };
        }
        const drawCircles = []
        for (const drillHole of drillHoles) {
            if (!drillHole || !drillHole.includes('/')) {
                return { message: item.orderNumber + ': Bohrlochmaße müssen mit einem / getrennt sein.' }
            }
            const [x, y] = drillHole.split('/')
            if (!x || !y || x === null || y === null) {
                return { message: item.orderNumber + ': Bohrlochmaße müssen mit einem / getrennt sein.' }
            }
            if (x.includes('mm') || y.includes('mm')) {
                return { message: item.orderNumber + ': Prüfe die Maße der Bohrlöcher, Angabe sollte in cm sein.' }
            }
            // replace , with . for float conversion
            const xNumber = x.replace(',', '.');
            const yNumber = y.replace(',', '.');

            if (isNaN(parseFloat(xNumber)) || isNaN(parseFloat(yNumber))) {
                return { message: item.orderNumber + ': Bohrlochmaße müssen Zahlen enthalten' }
            }

            drawCircles.push({ x: parseFloat(xNumber) * 10, y: parseFloat(yNumber) * 10 });
        }
        d.setActiveLayer('zuschnitt');
        drawCircles.map(c => d.drawCircle(c.x, c.y, holeRadius));
    }

    const dxfString = d.toDxfString();
    const blob = new Blob([dxfString], { type: 'application/dxf' });
    return { blob, message: 'success', filename: filename };
};