/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "fbb440a934f34b9c9987424472bbb46a",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "fbb440a934f34b9c9987424472bbb46a",
            "region": "eu-central-1"
        }
    },
    "aws_cloud_logic_custom": [
        {
            "name": "dhlparcelsapi",
            "endpoint": "https://l1segrppmc.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "dpdparcelsapi",
            "endpoint": "https://34dyyiwnx0.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "glsparcelsapi",
            "endpoint": "https://04s1qn0se1.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "mail",
            "endpoint": "https://699wu22h27.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "orders",
            "endpoint": "https://jhe31q19p9.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "upsparcelsapi",
            "endpoint": "https://ttaezffxg3.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "zohoapi",
            "endpoint": "https://a7nt8oudi3.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4i3vbalhhff5hjqrcdpwt37qgm.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rdzmofeaqzhztnzu7embbxb4iq",
    "aws_cognito_identity_pool_id": "eu-central-1:e434805a-abf6-46bb-9233-00bbafbf70cb",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_emhslS1T4",
    "aws_user_pools_web_client_id": "47becn6vtvo4r199m5geo508p4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fg-order-file-bucket141826-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
