import { AiOutlineLink } from 'react-icons/ai'
import { Color } from '../../theme'
import { Order, Product, Shipment } from '../../API'
import { getTrackingLink } from '../../utils/shipments-calc'
import { GoPackage } from 'react-icons/go'
import { Button } from '@aws-amplify/ui-react'
import { MdOutlineLocalPostOffice } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { useEffect } from 'react'
import { useAppDispatch } from '../../store/hooks'
import { setShipments } from '../../store/shippingSlice'
import ToolTipPopup from '../helper-components/ToolTipPopup'
import { rePrintLabel } from '../../utils'

function RetoureShipmentInfos({ orderItems, order }: { orderItems: Product[], order: Order }) {
    const dispatch = useAppDispatch()
    const { shipments } = useSelector((state: RootState) => state.shippingState)

    useEffect(() => {
        const shipmentsHere: Shipment[] = []
        for (const item of orderItems) {
            if (item.retoure_shipment && !shipmentsHere.find(s => s?.id === item?.retoure_shipment?.id)) {
                shipmentsHere.push(item.retoure_shipment)
            }
        }
        dispatch(setShipments(shipmentsHere))
        return () => {
            dispatch(setShipments([]))
        }
    }, [])

    const shipmentIds = orderItems.map(i => i.productRetoure_shipmentId)
    const shipmentsofOrder = shipments.filter(s => shipmentIds.includes(s.id))

    if (shipmentsofOrder.length < 1) {
        return null
    }

    return (
        <div>
            <div style={{ height: 15 }} />
            <div style={{ color: Color.red, display: 'flex', alignItems: 'center', gap: '0.2em' }}>
                <GoPackage /><div style={{ fontWeight: 'bold' }}>Retoure</div>
            </div>
            {shipmentsofOrder.map(shipment =>
                <div key={shipment.id}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ textDecoration: 'underline', alignItems: 'center', display: 'flex' }}>{shipment.serviceProvider}</div>
                        {shipment.labelData && <Button
                            size={'small'}
                            variation='link'
                            placeholder={undefined}
                            onClick={() => rePrintLabel({shipment, order})} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                        >
                            <MdOutlineLocalPostOffice />
                        </Button>}
                    </div>
                    {getTrackingLink(shipment) ?
                        <ToolTipPopup
                            tipText={getTrackingLink(shipment)}
                            trigger={
                                <div
                                    style={{ color: Color.lightblue, textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => window.open(getTrackingLink(shipment))}>
                                    <AiOutlineLink />{shipment.parcelNumber}
                                </div>
                            }
                        />
                        :
                        <ToolTipPopup
                            tipText={shipment.id}
                            trigger={<div
                                onClick={() => navigator.clipboard.writeText(shipment.id)}>#{shipment.id.split('-')[0]}...</div>} />
                    }
                    <div style={{ height: '0.5em' }} />
                </div>
            )}
            {/* {shippingCostSum > 0 && uniqueRetoureShipments.length > 1 && <div style={{ fontWeight: 'bold' }}>Gesamt: {shippingCostSum} €</div>} */}
        </div>)
}

export default RetoureShipmentInfos;