import { Alert, Button, Divider, ScrollView, TextField, ThemeProvider } from '@aws-amplify/ui-react';
import { StickyDiv } from "../components/helper-components/index";
import HeaderMenu from "../components/header/HeaderMenu";
import { useAppDispatch } from '../store/hooks';
import { zptheme } from '../theme';
import { FaPlus } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { createTourAsync, fetchFutureToursAsync, fetchPastToursAsync, resetTourState, setTourError, setTourInfo } from '../store/tourSlice';
import ToolTip from '../components/helper-components/ToolTip';
import { HiCloudDownload } from 'react-icons/hi';
import TourCard from '../components/tour/TourCard';
import { setMailError, setMailInfo } from '../store/mailSlice';

function TourPage() {
    const dispatch = useAppDispatch()
    const { usergroups } = useSelector((state: RootState) => state.userState)
    const { futureTours, pastTours, error, isLoading, nextTokenFutureTours, nextTokenPastTours, infoMsg } = useSelector((state: RootState) => state.tourState)
    const { mailError, mailInfo } = useSelector((state: RootState) => state.mailState)
    const [date, setDate] = useState<string>('')
    const today = new Date().toISOString()

    useEffect(() => {
        fetchfutureTours(null)
        return () => {
            dispatch(resetTourState())
        }
    }, [])

    async function addTour() {
        dispatch(setTourInfo(''))
        const variables = {
            input: {
                date: date
            }
        }
        await dispatch(createTourAsync(variables))
        setDate('')
    }

    async function fetchfutureTours(nextToken: string | null) {
        const response = await dispatch(fetchFutureToursAsync({ nextToken }))
        if (response.type === 'tours/fetchTours/fulfilled') {
            setDate('')
        }
    }

    async function fetchPastTours(nextToken: string | null) {
        await dispatch(fetchPastToursAsync({ nextToken }))
    }

    return (
        <ThemeProvider theme={zptheme}>
            <StickyDiv>
                <HeaderMenu />
                {error && <Alert
                    style={{ marginTop: '1em' }}
                    variation='error'
                    onDismiss={() => dispatch(setTourError(''))}
                    isDismissible={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {error}
                </Alert>}
                {mailError && <Alert
                    style={{ marginTop: '1em' }}
                    variation='error'
                    onDismiss={() => dispatch(setMailError(''))}
                    isDismissible={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {mailError}
                </Alert>}
                {mailInfo && <Alert
                    style={{ marginTop: '1em' }}
                    variation='info'
                    onDismiss={() => dispatch(setMailInfo(''))}
                    isDismissible={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {mailInfo}
                </Alert>}
                {infoMsg && <Alert
                    style={{ marginTop: '1em' }}
                    variation='info'
                    onDismiss={() => dispatch(setTourInfo(''))}
                    isDismissible={true} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    {infoMsg}
                </Alert>}
            </StickyDiv>
            <ScrollView placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                <div style={{ padding: '1em' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            {usergroups && usergroups.includes('managers') && <div style={{ display: 'flex', gap: '0.5em' }}>
                                <TextField
                                    label='tour-date'
                                    labelHidden={true}
                                    value={date}
                                    type='date'
                                    hasError={date ? date < today : false}
                                    onChange={(e: any) => {setDate(e.target.value); dispatch(setTourInfo(''))}}
                                />
                                <Button
                                    variation='primary'
                                    isDisabled={!date || date < today}
                                    onClick={addTour} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                >
                                    <FaPlus />
                                </Button>
                            </div>}
                        </div>
                        {nextTokenFutureTours &&
                            <ToolTip tipText={'weitere Touren laden'}>
                                <div>
                                    <Button
                                        minHeight={'2.5rem'}
                                        variation='primary'
                                        isDisabled={isLoading}
                                        onClick={() => fetchfutureTours(nextTokenFutureTours)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <HiCloudDownload />
                                    </Button>
                                </div>
                            </ToolTip>}
                    </div>
                    <div style={{ height: '1em' }} />
                    <div style={{ display: 'grid', gap: '1em' }}>
                        {futureTours && futureTours.length > 0 && futureTours.map((tour, index) =>
                            <TourCard tour={tour} key={index} />
                        )}
                        <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center', gap: '0.2em' }}>
                            <Divider size='large' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                            <h3>vergangene Touren</h3>
                            <Divider size='large' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                        </div>
                        {(pastTours.length === 0 || nextTokenPastTours) && <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                {pastTours?.length === 0 && <Button
                                    onClick={() => fetchPastTours(null)}
                                    variation='primary' placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                    vergangene Touren laden
                                </Button>}
                            </div>
                            <div>
                                {nextTokenPastTours &&
                                    <ToolTip tipText={'weitere Touren laden'}>
                                        <div>
                                            <Button
                                                minHeight={'2.5rem'}
                                                variation='primary'
                                                isDisabled={isLoading}
                                                onClick={() => fetchPastTours(nextTokenPastTours)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                <HiCloudDownload />
                                            </Button>
                                        </div>
                                    </ToolTip>}
                            </div>
                        </div>}
                        {pastTours && pastTours.length > 0 && pastTours.map((tour, index) =>
                            <TourCard tour={tour} key={index} />
                        )}
                    </div>
                </div>
            </ScrollView>
        </ThemeProvider>
    );
}

export default TourPage;
