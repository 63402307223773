import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { API } from 'aws-amplify'
import { Contractor, LabelFormat } from '../API'

export interface DPDShippingLabelData {
    OrderAction: string,
    OrderSettings: {
        ShipDate: string,
        LabelSize: string,
        LabelStartPosition: string
    },
    OrderDataList: [
        {
            ShipAddress: {
                Company: string | undefined,
                Gender: string | undefined,
                Salutation: string | undefined,
                FirstName: string,
                LastName: string,
                Name: string,
                Street: string,
                HouseNo: string,
                ZipCode: string,
                City: string,
                Country: string,
                State: string | undefined,
                Phone: string | undefined,
                Mail: string | undefined
            },
            ParcelData: {
                // Internes Referenzfeld zur Verknüpfung der DPD Paketnummer mit Ihrem internen System. (individuelle Angabe)
                YourInternalID: string,
                Content: string | undefined,
                Weight: string,
                Reference1: string | undefined,
                Reference2: string | undefined,
                ShipService: string
            }
        }
    ]
}

export interface DPDReturnLabelData {
    OrderAction: string,
    OrderSettings: {
        ShipDate: string,
        LabelSize: string,
        LabelStartPosition: string
    },
    ParcelData: {
        // Internes Referenzfeld zur Verknüpfung der DPD Paketnummer mit Ihrem internen System. (individuelle Angabe)
        YourInternalID: string,
        Content: string | undefined,
        Weight: string,
        Reference1: string | undefined,
        Reference2: string | undefined,
        ShipService: string
    }
}

export interface GLSShippingLabelData {
    shipment: {
        ShipmentReference: String[],
        ShipmentUnit: {
            Weight: number
        }[],
        Product: string,
        Consignee: {
            Category: ConsigneeCategory,
            Address: {
                Name1: string,
                Name2?: string | undefined,
                CountryCode: string,
                ZIPCode: string,
                City: string,
                Street: string,
                StreetNumber: string,
                eMail: string,
                MobilePhoneNumber?: string | undefined
            }
        }
    },
    contractor: Contractor | null | undefined
}

export interface GLSReturnLabelData {
    shipment: {
        ShipmentReference: string[],
        ShipmentUnit: {
            Weight: number
        }[],
        Product: string,
        Consignee: {
            Category: ConsigneeCategory,
            Address: {
                Name1: string,
                Name2: string,
                CountryCode: string,
                ZIPCode: string,
                City: string,
                Street: string,
                StreetNumber: string,
                eMail?: string | undefined,
                MobilePhoneNumber?: string | undefined
            }
        }
    },
    contractor: Contractor | null | undefined
}

export enum ConsigneeCategory {
    PRIVATE = 'PRIVATE',
    BUSINESS = 'BUSINESS'
}

export interface UPSShippingLabelData {
    ReferenceNumber: string,
    ShipTo: {
        Name: string,
        Address: {
            AddressLine: string[],
            City: string,
            PostalCode: string,
            CountryCode: string
        },
        Phone?: {
            Number: string
        }
    },
    Package: {
        Description?: string,
        Packaging: {
            Code: string
        },
        Dimensions: {
            UnitOfMeasurement: {
                Code: string
            },
            Length: string,
            Width: string,
            Height: string
        },
        PackageWeight: {
            UnitOfMeasurement: {
                Code: string
            },
            Weight: string
        }
    },
    location: Sender
}

export interface UPSReturnLabelData {
    ReferenceNumber: string,
    ShipFrom: {
        Name: string,
        Address: {
            AddressLine: string[],
            City: string,
            PostalCode: string,
            CountryCode: string
        },
        Phone?: {
            Number: string
        }
    },
    Package: {
        Description?: string,
        Packaging: {
            Code: string
        },
        Dimensions: {
            UnitOfMeasurement: {
                Code: string
            },
            Length: string,
            Width: string,
            Height: string
        },
        PackageWeight: {
            UnitOfMeasurement: {
                Code: string
            },
            Weight: string
        }
    },
    contractorNumber: Sender
}


export interface DHLShippingLabelData {
    referenceNumber: string,
    contractor: Contractor | null | undefined,
    consignee: {
        // name is used for Packstation
        name?: string | undefined,
        // name1 is used for normal name
        name1?: string | undefined,
        name2?: string | undefined,
        name3?: string | undefined,
        addressStreet: string,
        lockerID?: string | undefined,
        postNumber?: string | undefined,
        postalCode: string,
        city: string,
        country: string,
        email: string
    },
    details: {
        dim: {
            uom: string, // cm || mm
            height: number,
            length: number,
            width: number
        },
        weight: {
            uom: string, // kg || g
            value: number
        }
    }
}

export interface DHLReturnLabelData {
    referenceNumber: string,
    contractor: Contractor | null | undefined,
    shipper: {
        name1: string,
        name2?: string | undefined,
        name3?: string | undefined,
        addressStreet: string,
        postalCode: string,
        city: string,
        country: string,
        email: string
    },
    details: {
        dim: {
            uom: string, // cm || mm
            height: number,
            length: number,
            width: number
        },
        weight: {
            uom: string, // kg || g
            value: number
        }
    }
}

export enum Sender {
    'F000' = 'F000', // NIPSILD
    'F001' = 'F001', // ZP-LURUP, ALPERS
    'F002' = 'F002', // ZP-SCHENEFELD, HAMBURGER-HOLZMANUFAKTUR
    'F003' = 'F003', // SCHALLER
    'F004' = 'F004', // SCHRÖTER
    'F008' = 'F008', // ALPERS-VERPACKUNG
    'F009' = 'F009', // HHM-VERPACKUNG
    'F011' = 'F011'  // HHM-SOLO
}
interface shippingLabelState {
    isLoading: boolean
    success: string | undefined
    error: [string] | undefined
    errorCodes: [number] | undefined
    noPickupDays: string | undefined
}

const initialShippingLabelState = {
    isLoading: false,
    success: undefined,
    error: undefined,
    errorCodes: undefined,
    noPickupDays: undefined,
} as shippingLabelState

const dpdHeader = {
    'Content-Type': 'application/json',
    'Version': '100',
    'Language': 'de_DE',
    'PartnerCredentials-Name': `${ process.env.REACT_APP_DPD_PARTNER_NAME }`,
    'PartnerCredentials-Token': `${ process.env.REACT_APP_DPD_PARTNER_TOKEN }`,
    'UserCredentials-cloudUserID': `${ process.env.REACT_APP_DPD_USER_ID_LURUP }`,
    'UserCredentials-Token': `${ process.env.REACT_APP_DPD_USER_TOKEN_LURUP }`
}

export const createDPDShippingLabel = createAsyncThunk(
    'shippingLabel/dpd/createDPDShippingLabel',
    async ({ parcelData, contractor }: { parcelData: DPDShippingLabelData, contractor: Contractor | null | undefined }) => {
        const apiName = 'dpdparcelsapi';
        const path = '/setOrder';
        const init = {
            body: {
                parcelData,
                contractor
            }
        }
        try {
            const response = await API.post(apiName, path, init)

            if (response.ErrorDataList) {
                const errorStrings = response.ErrorDataList.map((e: any) => e.ErrorMsgLong)
                const errorCodes = response.ErrorDataList.map((e: any) => e.ErrorCode)
                if (errorCodes.includes('DPD_WEBSERVICE_MESSAGE')) {
                    errorStrings.push('Prüfe ob die Zieladresse auf einer Insel liegt.')
                }
                throw new Error([...errorStrings].join(' '))
            }
            const base64pdf = response.LabelResponse.LabelPDF
            const parcelNumber = response.LabelResponse.LabelDataList[0].ParcelNo
            const linkSource = `data:application/pdf;base64,${ base64pdf }`;
            const downloadLink = document.createElement("a");
            const fileName = `labels_${ parcelData.OrderDataList[0].ParcelData.YourInternalID }-${ parcelData.OrderDataList[0].ShipAddress.LastName }-${ parcelNumber }.pdf`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            return { parcelNumber, status: response.status, labelFormat: LabelFormat.pdf, base64: base64pdf }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? error.response.data : error
            throw new Error(errorMessage)
        }
    })

export const createDPDReturnLabel = createAsyncThunk(
    'shippingLabel/dpd/createDPDReturnLabel',
    async ({ labelData, contractor }: { labelData: DPDReturnLabelData, contractor: Contractor | null | undefined }) => {
        const apiName = 'dpdparcelsapi';
        const path = '/returns';
        const init = {
            body: {
                labelData,
                contractor
            }
        }
        try {
            const response = await API.post(apiName, path, init)
            if (response.ErrorDataList) {
                const errorMessages = response.ErrorDataList.map((e: any) => e.ErrorMsgLong)
                const errorCodes = response.ErrorDataList.map((e: any) => e.ErrorCode)
                if (errorCodes.includes('DPD_WEBSERVICE_MESSAGE')) {
                    errorMessages.push('Prüfe ob die Zieladresse auf einer Insel liegt.')
                }
                throw new Error([...errorMessages].join(' '))
            }
            const base64pdf = response.LabelResponse.LabelPDF
            const parcelNumber = response.LabelResponse.LabelDataList[0].ParcelNo
            const linkSource = `data:application/pdf;base64,${ base64pdf }`;
            const downloadLink = document.createElement("a");
            const fileName = `labels_Retoure-${ labelData.ParcelData.YourInternalID }-${ parcelNumber }.pdf`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            return { parcelNumber, status: response.status, labelFormat: LabelFormat.pdf, base64: base64pdf }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
            throw new Error(errorMessage)
        }
    })

export const getDPDZipCodeRules = createAsyncThunk(
    'shippingLabel/dpd/getZipCodeRules',
    async () => {
        const response = await fetch(`${ process.env.REACT_APP_DPD_BASE_URL }/api/v1/ZipCodeRules`, {
            method: 'GET',
            headers: dpdHeader
        })
        const body = await response.json()
        const noPickUpDays = body.ZipCodeRules.NoPickupDays
        return noPickUpDays
    }
)

export const createGLSShippingLabel = createAsyncThunk(
    'shippingLabel/gls/createGLSShippingLabel',
    async (data: GLSShippingLabelData) => {
        const apiName = 'glsparcelsapi';
        const path = '/shipments';
        const init = {
            body: data
        }
        try {
            const response = await API.post(apiName, path, init)
            if (!response.error) {
                const responseData = response.CreatedShipment
                const base64pdf = responseData.PrintData[0].Data
                const parcelNumber = responseData.ParcelData[0].ParcelNumber
                const trackID = responseData.ParcelData[0].TrackID
                const linkSource = `data:application/pdf;base64,${ base64pdf }`;
                const downloadLink = document.createElement("a");
                const fileName = `labels_${ data.shipment.ShipmentReference[0] }-${ data.shipment.Consignee.Address.Name1 }-${ parcelNumber }.pdf`;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                return { parcelNumber, trackID, base64: base64pdf, labelFormat: LabelFormat.pdf }
            } else {
                throw new Error(response.error)
            }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
            throw new Error(errorMessage)
        }
    })

export const createGLSReturnLabel = createAsyncThunk(
    'shippingLabel/gls/createGLSReturnLabel',
    async (data: GLSReturnLabelData) => {
        const apiName = 'glsparcelsapi';
        const path = '/returns';
        const init = {
            body: data
        }
        try {
            const response = await API.post(apiName, path, init)
            if (!response.error) {
                const responseData = response.CreatedShipment
                const base64pdf = responseData.PrintData[0].Data
                const parcelNumber = responseData.ParcelData[0].ParcelNumber
                const trackID = responseData.ParcelData[0].TrackID
                const linkSource = `data:application/pdf;base64,${ base64pdf }`;
                const downloadLink = document.createElement("a");
                const fileName = `labels_Retoure-${ data.shipment.ShipmentReference[0] }-${ data.shipment.Consignee.Address.Name1 }-${ parcelNumber }.pdf`;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
                return { parcelNumber, trackID, base64: base64pdf, labelFormat: LabelFormat.pdf }
            } else {
                throw new Error(JSON.stringify(response.error))
            }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? error.response.data : JSON.stringify(error)
            throw new Error(errorMessage)
        }

    })

export const cancelGLSShippingLabel = createAsyncThunk(
    'shippingLabel/gls/cancelGLSShippingLabel',
    async(trackID: string) => {
        const apiName = 'glsparcelsapi';
        const path = '/cancel';
        const init = {
            body: {
                trackID
            }
        }
        try {
            const response = await API.post(apiName, path, init)
            return response
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? error.response.data : JSON.stringify(error)
            throw new Error(errorMessage)
        }
    }
)

export const createUPSShippingLabel = createAsyncThunk(
    'shippingLabel/ups/createUPSShippingLabel',
    async (data: UPSShippingLabelData) => {
        const apiName = 'upsparcelsapi';
        const path = '/shipments';
        const init = {
            body: data
        }
        try {
            const response = await API.post(apiName, path, init)

            // Test and Production differs in test it is PackageResults[0]
            const upsPackage = response.PackageResults[0] || response.PackageResults
            const trackID = upsPackage.TrackingNumber
            const parcelNumber = response.ShipmentIdentificationNumber
            const linkSource = `data:application/gif;base64,${ upsPackage.ShippingLabel.GraphicImage }`;
            const downloadLink = document.createElement("a");
            const fileName = `labels_${ data.ReferenceNumber }-${ data.ShipTo.Name.replace(' ', '-') }.gif`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            return { trackID, parcelNumber, labelFormat: LabelFormat.gif, base64: upsPackage.ShippingLabel.GraphicImage }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? error.response.data : JSON.stringify(error)
            throw new Error(errorMessage)
        }
    })

export const createUPSReturnLabel = createAsyncThunk(
    'shippingLabel/ups/createUPSReturnLabel',
    async (data: UPSReturnLabelData) => {
        const apiName = 'upsparcelsapi';
        const path = '/returns';
        const init = {
            body: data
        }
        try {
            const response = await API.post(apiName, path, init)

            // Test and Production differs in test it is PackageResults[0]
            const upsPackage = response.PackageResults[0] || response.PackageResults
            const trackID = upsPackage.TrackingNumber
            const parcelNumber = response.ShipmentIdentificationNumber
            const linkSource = `data:application/gif;base64,${ upsPackage.ShippingLabel.GraphicImage }`;
            const downloadLink = document.createElement("a");
            const fileName = `labels_Retoure-${ data.ReferenceNumber }-${ data.ShipFrom.Name.replace(' ', '-') }.gif`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            return { trackID, parcelNumber, labelFormat: LabelFormat.gif, base64: upsPackage.ShippingLabel.GraphicImage }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? error.response.data : JSON.stringify(error)
            throw new Error(errorMessage)
        }
    })

export const createDHLShippingLabel = createAsyncThunk(
    'shippingLabel/dhl/createDHLShippingLabel',
    async (data: DHLShippingLabelData) => {
        const apiName = 'dhlparcelsapi';
        const path = '/shipments';
        const init = {
            body: data
        }
        try {
            const response = await API.post(apiName, path, init)

            const parcelNumber = response.shipmentNo
            const linkSource = `data:application/pdf;base64,${ response.b64 }`;
            const downloadLink = document.createElement("a");
            const name = data.consignee.name1 || data.consignee.name || data.consignee.name2 || data.consignee.name3 || ''
            const fileName = `labels_${ data.referenceNumber }-${ name.replace(' ', '-') }.pdf`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            return { parcelNumber, labelFormat: LabelFormat.pdf, base64: response.b64 }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
            throw new Error(errorMessage)
        }
    })

export const createDHLReturnLabel = createAsyncThunk(
    'shippingLabel/dhl/createDHLReturnLabel',
    async (data: DHLReturnLabelData) => {
        const apiName = 'dhlparcelsapi';
        const path = '/returns';
        const init = {
            body: data
        }
        try {
            const response = await API.post(apiName, path, init)
            const parcelNumber = response.shipmentNo
            const linkSource = `data:application/pdf;base64,${ response.b64 }`;
            const downloadLink = document.createElement("a");
            const fileName = `Retoure-${ data.referenceNumber }-${ data.shipper.name1.replace(' ', '-') }.pdf`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            return { parcelNumber, labelFormat: LabelFormat.pdf, base64: response.b64 }
        } catch (error: any) {
            const errorMessage = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
            throw new Error(errorMessage)
        }
    })

export const shippingLabelSlice = createSlice({
    name: 'shippinglabels',
    initialState: initialShippingLabelState,
    reducers: {
        resetShippingLabelState: (state) => {
            state.isLoading = false
            state.error = undefined
            state.errorCodes = undefined
            state.noPickupDays = undefined
        },
        setshippingLabelError: (state, action: PayloadAction<[string] | undefined>) => {
            state.error = action.payload
            state.errorCodes = undefined
        },
        setShippingLabelSuccess: (state, action: PayloadAction<string | undefined>) => {
            state.success = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createDPDShippingLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createDPDShippingLabel.fulfilled, (state, action) => {
            state.isLoading = false
        })
        builder.addCase(createDPDShippingLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ action.error.message }`
            console.error(JSON.stringify(action))
            state.error = [errorMessage]
        })
        builder.addCase(createDPDReturnLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createDPDReturnLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(createDPDReturnLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ action.error.message }`
            console.error(JSON.stringify(action))
            state.error = [errorMessage]
        })
        builder.addCase(getDPDZipCodeRules.fulfilled, (state, action) => {
            state.noPickupDays = action.payload
        })
        builder.addCase(createGLSShippingLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createGLSShippingLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(createGLSShippingLabel.rejected, (state, action) => {
            state.isLoading = false
            console.log('error appeard: ', action)
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ action.error.message }`
            console.error(JSON.stringify(action))
            state.error = [errorMessage]
        })
        builder.addCase(createGLSReturnLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createGLSReturnLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(cancelGLSShippingLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(cancelGLSShippingLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(cancelGLSShippingLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ action.error.message }`
            state.error = [errorMessage]
        })
        builder.addCase(createGLSReturnLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ action.error.message }`
            state.error = [errorMessage]
        })
        builder.addCase(createUPSShippingLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createUPSShippingLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(createUPSShippingLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ JSON.stringify(action.error.message) }`
            console.error(JSON.stringify(action))
            state.error = [errorMessage]
        })
        builder.addCase(createUPSReturnLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createUPSReturnLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(createUPSReturnLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ JSON.stringify(action.error.message) }`
            console.error(JSON.stringify(action))
            state.error = [errorMessage]
        })
        builder.addCase(createDHLShippingLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createDHLShippingLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(createDHLShippingLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ JSON.stringify(action.error.message) }`
            console.error(JSON.stringify(action))
            state.error = [errorMessage]
        })
        builder.addCase(createDHLReturnLabel.pending, (state, action) => {
            state.isLoading = true
        })
        builder.addCase(createDHLReturnLabel.fulfilled, (state, action) => {
            state.isLoading = false
            state.error = undefined
        })
        builder.addCase(createDHLReturnLabel.rejected, (state, action) => {
            state.isLoading = false
            const errorMessage = `Es ist ein Fehler aufgetreten, bitte wende dich an die Administratorin. ${ action.error.message }`
            console.error(JSON.stringify(action))
            state.error = [errorMessage]
        })
    }
})

export const { setshippingLabelError, resetShippingLabelState, setShippingLabelSuccess } = shippingLabelSlice.actions

export default shippingLabelSlice.reducer