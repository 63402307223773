import { Divider, SelectField, ThemeProvider, withAuthenticator } from '@aws-amplify/ui-react';
import { Button } from '@aws-amplify/ui-react';
import { Color, zptheme } from '../../theme';
import { NumberSelectIcon, OrderCardDiv, OrderInfos, OrderItems, OrderNumber, OrderStatusContainer } from '../helper-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { calculateDeliveryDate, finishedItem, getDeliveryTimeInDays, getNextTask, onGoingItemBeforePacking, orderSourceName, prettyDate, statusColor, statusIcon, statusName } from '../../utils';
import { FiMaximize2, FiMinimize2 } from 'react-icons/fi';
import { BsExclamationOctagonFill } from 'react-icons/bs';
import { API, graphqlOperation } from 'aws-amplify';
import { getOrder } from '../../graphql/queries';
import { HiPencil, HiPrinter } from 'react-icons/hi';
import { createLabelMessages } from '../../utils/create-labeldata';
import { useAppDispatch } from '../../store/hooks';
import { addSelectedOrderId, createDocument, removeSelectedOrderId, setLoading } from '../../store/printDocumentsSlice';
import { removeItemUpdate, saveItemUpdate, setEditable, setItemsError, updateItemAsync, updateOrderAsync } from '../../store/itemsSlice';
import { CreateShipmentMutationVariables, CustomerRequestApproved, Option, Order, OrderSource, OrderState, Product, ProductStatus, UpdateOrderInput, UpdateOrderMutationVariables, UpdateProductInput, UpdateProductMutationVariables } from '../../API';
import { RxCrossCircled } from 'react-icons/rx';
import { IoMdSave } from 'react-icons/io';
import { IoClose, IoQrCodeOutline } from 'react-icons/io5';
import { resetWooState, wooUpdateOrderAsync } from '../../store/woocommerceSlice';
import { ShippingMethods } from '../../utils/Constants';
import ShipmentInfos from './ShipmentInfos';
import { createShipmentAsync, setShipmetsErrorMessage } from '../../store/shippingSlice';
import { createNewShipmentVars } from '../../utils/create-new-shipment-vars';
import { getUniqueShipmentsOfOrder } from '../../utils/shipments-calc';
import AddressPopup from './AddressPopup';
import { useLocation } from 'react-router-dom';
import { MdOutlineLockClock } from 'react-icons/md';
import RetoureShipmentInfos from './RetoureShipmentInfos';
import { sendMailTemplate } from '../../store/mailSlice';
import ToolTipPopup from '../helper-components/ToolTipPopup';
const ReklaButton = lazy(() => import('./buttonsetc/ReklaButton'))
const ProductCosts = lazy(() => import('./ProductCosts'))
const ItemDetails = lazy(() => import('./ItemDetails'))
const ToDoList = lazy(() => import('./ToDoList'))
const OrderCosts = lazy(() => import('./OrderCosts'))

function OrderItemsCard({ orderId }: any) {
    const dispatch = useAppDispatch()
    const location = useLocation();
    const { usergroups } = useSelector((state: RootState) => state.userState)
    const { items, filters, updateItemInputs, editable } = useSelector((state: RootState) => state.itemsState)
    const { isLoading, selectedOrderIds } = useSelector((state: RootState) => state.documentState)
    const { contractors } = useSelector((state: RootState) => state.configState)
    const stateMinimize = useSelector((state: RootState) => state.itemsState.minimize)

    const [minimize, setMinimize] = useState(stateMinimize)
    const [orderFormData, setOrderFormData] = useState<UpdateOrderInput>()
    const [otherOngoingItems, setOtherOngoingItems] = useState<Product[]>([])
    const [otherItemsOfOrder, setOtherItemsOfOrder] = useState<Product[]>([])

    function filterItems(item: Product) {
        return item.productOrderId === orderId && (item.name === filters.productName || !filters.productName)
    }

    const orderItems = items.filter(filterItems)
    const orderInfos = orderItems[0].order

    useEffect(() => {
        const fetchOrder = async () => {
            const response = await API.graphql(graphqlOperation(getOrder, { id: orderId }))
            //@ts-ignore
            const otherItems = response.data.getOrder.products.items.filter((i: Product) => !orderItems.map(oI => oI.id).includes(i.id))
            setOtherItemsOfOrder(otherItems)
        }
        fetchOrder()
        return () => { setOtherItemsOfOrder([]) }
    }, [orderInfos])

    useEffect(() => {
        if (location.pathname === '/orders/readyForPackagingItems') {
            setOtherOngoingItems(otherItemsOfOrder.filter(i => onGoingItemBeforePacking(i)).filter(filterItems))
            if (filters.contractor !== 'Alle') {
                setOtherOngoingItems(otherItemsOfOrder.filter(i => i.contractor && i.contractor.id === filters.contractor && onGoingItemBeforePacking(i)).filter(filterItems))
            }
        }

        return () => { setOtherOngoingItems([]) }
    }, [otherItemsOfOrder, filters])

    // set minimize to itemsState.minimize if it has been changed
    useEffect(() => {
        setMinimize(stateMinimize)
    }, [stateMinimize])

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    const escFunction = useCallback((event: KeyboardEvent) => {
        if (event.key === "Escape") {
            onClickAbort()
        }
    }, []);

    async function onClickPrintLabel() {
        // this should not happen because button is disabeld if filters.contractor === 'Alle'
        if (filters.contractor === 'Alle') {
            return
        }
        if (!orderInfos) return
        // fetch all items of order to make sure the order of the items is the same as if the label is printed in bulk on new items page
        const response = await API.graphql(graphqlOperation(getOrder, { id: orderId }))
        // @ts-ignore
        const allOrderItems = response.data.getOrder.products.items
        //@ts-ignore
        const allOrderItemsOfContractor = response.data.getOrder.products.items.filter((i: Product) => i.productContractorId === filters.contractor)
        // don`t print a label if the order contains an item with "Anmerkungen" which are not approved yet
        if (allOrderItemsOfContractor.some((i: Product) => i.extraOptions?.find(o => o?.name?.includes('Anmerkungen')) && !i.customer_request_approved)) {
            dispatch(setItemsError('Das Label kann nicht gedruckt werden, weil noch nicht alle Anmerkungen geprüft wurden. Wende dich an den CS um die Anmerkungen prüfen zu lassen.'))
            return
        }
        const data = await createLabelMessages(orderItems, allOrderItemsOfContractor)
        if (data.messages.length > 0) {
            const response = await dispatch(createDocument({ data, outputName: 'labels.pdf', templateName: 'samples/Produktion_Allgemein.docx' }))
            // @ts-ignore
            if (response.payload.statusCode === 200) {
                for (const item of orderItems) {
                    if (!item.status || item.status === ProductStatus.new) {
                        const nextTask = getNextTask(item)
                        const variables = {
                            input: {
                                id: item.id,
                                status: ProductStatus.in_progress,
                                contractor_status: `${ item.productContractorId }-${ ProductStatus.in_progress }`,
                                in_progressAt: new Date().toISOString(),
                                currentTask: nextTask || null,
                                contractor_currentTask: nextTask ? `${ item.productContractorId }-${ nextTask }` : null
                            }
                        } as unknown as UpdateProductMutationVariables
                        await dispatch(updateItemAsync(variables))
                    }
                }
                // update the status in woocommerce when first item is set to in_progress
                if (orderInfos.source === OrderSource.woocommerce && allOrderItems.every((i: Product) => i.status === ProductStatus.new)) {
                    const response = await dispatch(wooUpdateOrderAsync({ orderNumber: orderInfos.orderNumber, data: { status: 'production' } }))
                    //@ts-ignore
                    if (response.payload.statusCode !== 200 && process.env.REACT_APP_ENV === 'prod') {
                        // ignore the error in dev environment because often the order doesn't exist in woocommerce staging
                        return
                    }
                    dispatch(resetWooState())
                }
            }
        }
    }

    function onClickEdit() {
        dispatch(setEditable(orderId))
    }

    function onClickAbort() {
        if (editable === orderId) {
            dispatch(setEditable(''))
            dispatch(removeItemUpdate([]))
            setOrderFormData(undefined)
        }
    }
    async function onClickSave() {
        if (updateItemInputs.length > 0) {
            for (const data of updateItemInputs) {
                const variables = {
                    input: { ...data }
                }
                dispatch(updateItemAsync(variables))
            }
            // TODO: add modal before exporting order to make sure that order should be exported!
            // if a contractor is set to F012 trigger export to FG 
            const contractorF012 = updateItemInputs.find(el => el.productContractorId === contractors.find(c => c.number === 'F012')?.id)
            if (contractorF012) {
                const init = {
                    body: { orderId: orderInfos?.id }
                }
                try {
                    dispatch(setLoading(true))
                    await API.post('orders', '/fgorderexport', init)
                } catch (error) {
                    console.error(error)
                    dispatch(setItemsError('Der Auftrag konnte nicht übertragen werden: ' + JSON.stringify(error)))
                }
                dispatch(setLoading(false))
            }
            // if a contractor is set to F010 trigger export to speedmaster 
            const contractorF010 = updateItemInputs.find(el => el.productContractorId === contractors.find(c => c.number === 'F010')?.id)
            if (contractorF010) {
                dispatch(setLoading(true))
                const init = {
                    body: { orderId: orderInfos?.id }
                }
                try {
                    await API.post('orders', '/smorderexport', init)
                } catch (error) {
                    console.error(error)
                    dispatch(setItemsError('Der Auftrag konnte nicht übertragen werden: ' + JSON.stringify(error)))
                }
                dispatch(setLoading(false))
            }
        }
        if (orderFormData) {
            const variables = {
                input: { ...orderFormData }
            }
            if (!orderInfos) {
                return
            }
            if (orderFormData.shipping_method && orderFormData.shipping_method.includes('Express')) {
                const allOrderItems = [...orderItems, ...otherItemsOfOrder]
                let orderDeliveryDate = orderInfos.date_paid
                for (const item of allOrderItems) {
                    const deliveryTime = item.delivery_time ? item.delivery_time : '10 Tage'
                    const deliveryDate = calculateDeliveryDate(orderInfos.date_paid, getDeliveryTimeInDays(deliveryTime), orderFormData.shipping_method)
                    if (deliveryDate > orderDeliveryDate) orderDeliveryDate = deliveryDate
                    const itemVariables = {
                        input: {
                            id: item.id,
                            delivery_date: deliveryDate
                        }
                    }
                    dispatch(updateItemAsync(itemVariables))
                    variables.input.delivery_date = orderDeliveryDate
                }
            }
            dispatch(updateOrderAsync(variables))
        }
        dispatch(removeItemUpdate([]))
        setOrderFormData(undefined)
        dispatch(setEditable(''))
    }

    async function createQRCode(products: Product[]) {
        for (const product of products) {
            const variables = {
                input: {
                    id: product.id,
                    shipping_label_printed: true,
                }
            }
            await dispatch(updateItemAsync(variables))
        }
        const type = orderInfos?.shipping_method === ShippingMethods.EIGENAUSFAHRT ? 'Ausfahrt durch Zuschnittprofi' : 'Abholung'
        const itemCount = orderItems.reduce((pv, cv) => pv + (cv.quantity || 1), 0)
        window.open(`https://zuschnittprofi.de/abholprint/?order=${ orderInfos?.orderNumber }&custname=${ orderInfos?.customer.company ? `${ orderInfos?.customer.company } - ${ orderInfos?.customer.last_name }` :
            `${ orderInfos?.customer.last_name }` }&itemcount=${ itemCount }&type=${ type }`)
    }

    async function confirmFinishPickUpOrder(products: Product[], orderId: string) {
        dispatch(setItemsError(''))
        // confirm packing of each product
        for (const product of products) {
            if (product.order?.shipping_method === ShippingMethods.EIGENAUSFAHRT && !product.tourProductsId) {
                dispatch(setItemsError('Der Auftrag kann nicht fertig gestellt werden, da noch keine Tour ausgewählt wurde'))
                return
            }
            if (product.status !== ProductStatus.ready_to_pack || finishedItem(product)) {
                continue
            }
            // it might be that items where resetet and shipped than so we set status billed if it was already billed
            const status = product.billedAt ? ProductStatus.billed : ProductStatus.packing_confirmed
            const variables = {
                input: {
                    id: product.id,
                    status,
                    contractor_status: `${ product.productContractorId }-${ status }`,
                    finishedAt: new Date().toISOString(),
                    currentTask: null,
                    contractor_currentTask: null
                } as unknown as UpdateProductInput
            }
            const updateItemAction = await dispatch(updateItemAsync(variables))
            // on error break up
            if (updateItemAction.type === updateItemAsync.rejected.type) {
                return
            }
        }
        const orderResponse = await API.graphql(graphqlOperation(getOrder, { id: orderInfos?.id }))
        //@ts-ignore
        const updatedOrder = orderResponse.data.getOrder
        // safe "shipments" of order in woo
        if (orderInfos?.source === OrderSource.woocommerce) {
            const shipmentsOfOrder = (getUniqueShipmentsOfOrder(updatedOrder))
            if (shipmentsOfOrder.length > 0) {
                await (dispatch(wooUpdateOrderAsync({ orderNumber: updatedOrder.orderNumber, data: { meta_data: [{ key: 'zp_shipments', value: shipmentsOfOrder }] } })))
            }
        }

        // check if other items are already finished because we updated all orderItems
        const allConfirmed = otherItemsOfOrder.every(finishedItem)
        if (orderInfos && allConfirmed) {
            // finish order in woo on error break up
            if (!orderInfos.source || orderInfos.source === OrderSource.woocommerce) {
                const response = await dispatch(wooUpdateOrderAsync({ orderNumber: orderInfos.orderNumber, data: { status: 'completed' } }))
                //@ts-ignore
                if (response.payload.statusCode !== 200 && process.env.REACT_APP_ENV === 'prod') {
                    // ignore the error in dev environment because often the order doesn't exist in woocommerce staging
                    return
                }
                dispatch(resetWooState())
            }

            // finish order in ddb if all items are confirmed and break up on error
            const variables = {
                input: {
                    id: orderInfos.id,
                    finishedAt: new Date().toISOString(),
                    state: OrderState.finished
                }
            } as unknown as UpdateOrderMutationVariables
            const updateOrderAction = await dispatch(updateOrderAsync(variables))
            if (updateOrderAction.type === updateOrderAsync.rejected.type) {
                return
            }
            if (orderInfos.source && orderInfos.source !== OrderSource.woocommerce && orderInfos.source !== OrderSource.rekla) {
                const init = {
                    body: {
                        payload: {
                            orderNumber: updatedOrder.orderNumber,
                            customer_first_name: updatedOrder.customer.first_name,
                            orderSource: updatedOrder.source,
                            TrackID: products[0].tour?.date,
                            serviceProvider: updatedOrder.shipping_method === ShippingMethods.EIGENAUSFAHRT ? 'Zuschnittprofi' : 'Auftrag kann abgeholt werden',
                            contractor: products[0].contractor?.name
                        },
                        webhookURL: 'https://flow.zoho.eu/20066910136/flow/webhook/incoming?zapikey=1001.ee4d9255fd96c5050f29e764b9e6275b.7c5b3b3712be5ee98726e7f902ce91e3&isdebug=false'
                    }
                }
                await API.post('zohoapi', '/triggerWebHook', init)
            }
            if (orderInfos && orderInfos.source === OrderSource.rekla) {
                notifyCustomerOnOrderFinished(updatedOrder.payload)
            }
        }
    }

    async function notifyCustomerOnOrderFinished(order: Order) {
        if (!order.customer || !order.customer.email) {
            dispatch(setShipmetsErrorMessage('Die Kundeninformationen fehlen, daher wurde keine E-Mail Benachrichtigung an den Kunden/ die Kundin versendet'))
            return
        }
        const shipping_infos = order.shipping_method === ShippingMethods.ABHOLUNGHH || order.shipping_method === ShippingMethods.EXPRESSABHOLUNG ?
            `
        <div
		style="font-weight: bold">
		Deine Bestellung steht zur Abholung bereit:<br></div>
		<table>
			<tbody>
				<tr>
					<th style="padding: 12px 12px 12px 0px; text-align: left;"
						class="align-left">
						Abholadresse
						Metall:<br>
					</th>
					<th style="padding: 12px; text-align: left;"
						class="align-left">
						Abholadresse
						Holz:<br>
					</th>
				</tr>
				<tr>
					<td
						style="padding: 12px 12px 12px 0px; width: 168.75px;">
						Luruper
						Hauptstr.
						54<br> 22547 Hamburg
					</td>
					<td
						style="padding: 12px; width: 190.453px;">
						Achter
						de
						Weiden
						12-22<br> 22869 Schenefeld
					</td>
				</tr>
			</tbody>
		</table>`
            :
            'Deine Bestellung ist fertig. Wir teilen dir in den nächsten Tagen mitt wann wir zu dir kommen um deine Bestellung auszuliefern.'
        // send order shipped notification to customer (because we are in ShipmentCard order is shipped not picked up or delivered by zp)
        const data = {
            template_key: '13ef.53e3308d7370c518.k1.0857ed60-3f6d-11ef-af7d-5254006dcfe6.190a140d736',
            template_name: 'order_ready',
            // subject is required but not used because subject is defined in the template
            subject: `Deine Zuschnittprofi.de Bestellung #${ order.orderNumber } ist fertig!`,
            email: order.customer.email,
            merge_info: {
                orderNumber: order.orderNumber,
                first_name: order.customer.first_name,
                names: orderItems.map(i => i?.name).join('<br><br>'),
                quantities: orderItems.map(i => i?.quantity).join('<br><br>'),
                shipping_infos
            }
        }
        // send email
        await dispatch(sendMailTemplate(data))
    }

    async function createShipmentForAllItems(orderItems: Product[]) {
        let variables = {} as CreateShipmentMutationVariables
        try {
            variables = createNewShipmentVars(orderItems, orderInfos?.shipping_method)
        }
        catch (error) {
            return dispatch(setShipmetsErrorMessage(`${ error }`))
        }
        const createShipmentAction = await dispatch(createShipmentAsync(variables))
        if (createShipmentAction?.meta.requestStatus === 'fulfilled') {
            if (createShipmentAction.payload) {
                //@ts-ignore
                const { shipment } = createShipmentAction.payload
                for (const product of orderItems) {
                    const variables = {
                        input: {
                            id: product.id,
                            productShipmentId: shipment.id
                        }
                    }
                    await dispatch(updateItemAsync(variables))
                }
            }
        }
    }

    async function createRetoureShipment(orderItems: Product[]) {
        let variables = {} as CreateShipmentMutationVariables
        try {
            variables = createNewShipmentVars(orderItems, orderInfos?.shipping_method, 'Retoure')
        }
        catch (error) {
            return dispatch(setShipmetsErrorMessage(`${ error }`))
        }
        const createShipmentAction = await dispatch(createShipmentAsync(variables))
        if (createShipmentAction?.meta.requestStatus === 'fulfilled') {
            if (createShipmentAction.payload) {
                //@ts-ignore
                const { shipment } = createShipmentAction.payload
                for (const product of orderItems) {
                    const variables = {
                        input: {
                            id: product.id,
                            productRetoure_shipmentId: shipment.id
                        }
                    }
                    await dispatch(updateItemAsync(variables))
                }
            }
        }
    }

    function renderEditButtons() {
        return (editable === orderId ?
            <div>
                <Button size={'small'} onClick={onClickSave} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                    <IoMdSave />
                </Button>
                <Button size={'small'} variation={'link'} onClick={onClickAbort} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <IoClose />
                </Button>
            </div>
            :
            <div>
                <Button size={'small'} onClick={() => onClickEdit()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                    <HiPencil />
                </Button>
            </div>
        )
    }

    function renderLabelPrintButton() {
        if (finishedOrder) {
            return <></>
        }
        if (orderItems.every(i => i.status !== ProductStatus.pending_return)) {
            return (
                <div>
                    <Button
                        size={'small'}
                        isLoading={isLoading}
                        // if order status is importing it is not finished yet and we don't want to start the production
                        isDisabled={filters.contractor === 'Alle' || orderInfos?.state === OrderState.importing}
                        onClick={() => onClickPrintLabel()} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
                        <HiPrinter />
                    </Button>
                </div>
            )
        }
    }

    const shipping_total = orderInfos?.shipping_total || 0
    const feeValues = orderInfos?.fees?.map(fee => fee?.total)
    const feeSum = feeValues ? feeValues.reduce((pv, cv) => (pv || 0) + (cv || 0), 0) : 0
    const shippingRevenues = shipping_total + (feeSum || 0)

    const orderSelected = selectedOrderIds.includes(orderId)
    const today = new Date();
    const delivery = orderInfos ? new Date(orderInfos.delivery_date) : '';
    //@ts-ignore
    const diffTime = Math.abs(delivery - today);
    const overdueDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const overdue = today > delivery

    const expressOrder = orderInfos?.shipping_method?.includes('Express')
    const finishedOrder = orderInfos?.state === OrderState.finished
    const allItemsFinished = orderItems.every((item: Product) => item.status === ProductStatus.packing_confirmed || item.status === ProductStatus.billed || item.status === ProductStatus.cancelled)

    if (minimize) {
        return (
            <ThemeProvider theme={zptheme}>
                <OrderCardDiv key={orderInfos?.id} id='order-items-card-mini'>
                    <OrderInfos>
                        <OrderNumber style={{ flex: 2 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '0.2rem', alignItems: 'center' }}>
                                {renderLabelPrintButton()}
                                <div style={{ fontWeight: 'bold', display: 'flex', gap: '0.2rem', whiteSpace: 'nowrap' }}>
                                    # {orderInfos?.orderNumber}
                                    {orderInfos?.state !== OrderState.importing &&
                                        <div style={{ display: 'grid', alignItems: 'center' }}>{orderSelected ? <RxCrossCircled onClick={() => dispatch(removeSelectedOrderId(orderId))} /> :
                                            <NumberSelectIcon onClick={() => dispatch(addSelectedOrderId(orderId))} />}
                                        </div>
                                    }
                                </div>
                            </div>
                            {!finishedOrder && expressOrder && <div style={{ color: Color.red, fontSize: 'small', textAlign: 'inherit' }}>Express</div>}
                            {!finishedOrder && overdue && <div style={{ color: Color.red, fontSize: 'small', textAlign: 'inherit' }}>überfällig: {overdueDays} {overdueDays === 1 ? 'Tag' : 'Tage'}</div>}
                            {usergroups && usergroups.includes('managers') && <div>{orderInfos?.total?.toFixed(2)} €</div>}
                        </OrderNumber>
                        <OrderItems style={{ flex: 15 }}>
                            {[...orderItems, ...otherOngoingItems].map((item: Product, index: number) => (
                                <div key={index} id='items-div-mini' style={otherOngoingItems.find(i => i.id === item.id) ? { color: Color.lightgrey } : { color: Color.blue }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                        <div style={{ flex: 1, display: 'flex', gap: '0.2em', whiteSpace: 'nowrap' }}>
                                            <ToolTipPopup tipText={`Produktstatus: ${ statusName(item.status) }`} trigger={
                                                <p style={{ paddingTop: '0.1em' }}>{statusIcon(item.status)}</p>
                                            } />
                                            <p>{item.quantity} Stück</p>
                                        </div>
                                        <p style={{ flex: 4 }} id='item-name-mini'>
                                            {item.name.split(' - ')[0]}</p>
                                        <div style={{ flex: 7, display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                                {item.variations && item.variations.map((variation: string | null, index: number) =>
                                                    <p key={index}>{variation}</p>
                                                )}
                                                {item.length && item.length > 0 && <p>Länge: {item.length} cm</p>}
                                                {item.width && item.width > 0 && <p>Breite: {item.width} cm</p>}
                                                {item.extraOptions?.find(el => el?.value.includes('Schliff an der Länge entlang')) && <p>Schliff an der Länge entlang</p>}
                                                {item.extraOptions?.find(el => el?.value.includes('Schliff an der Breite entlang')) && <p>Schliff an der Breite entlang</p>}
                                                {(item.backoffice_note || item.customer_request_approved === CustomerRequestApproved.yes) && <div style={{ marginTop: '1.2em', color: Color.red }}><BsExclamationOctagonFill /></div>}
                                            </div>
                                            {index > 0 && <p style={{ color: Color.lightgrey, textAlign: 'right' }} id='item-contractor-name'> {item.contractor?.name}</p>}
                                            <div>
                                                {index === 0 &&
                                                    <div style={{ textAlign: 'right' }}>
                                                        <Button
                                                            id='maximize-button'
                                                            size='small'
                                                            onClick={() => setMinimize(false)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                                            <FiMaximize2 />
                                                        </Button>
                                                        <div style={{ color: Color.lightgrey }} id='item-contractor-name'> {item.contractor?.name}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {index + 1 < [...orderItems, ...otherOngoingItems].length && <Divider size='small' width={'99%'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                                </div>
                            ))}
                        </OrderItems>
                    </OrderInfos>
                </OrderCardDiv>
            </ThemeProvider>
        )
    }

    return (
        <ThemeProvider theme={zptheme}>
            <OrderCardDiv key={orderId} id={'order-card-max'}>
                <OrderStatusContainer>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'row', gap: '0.5em' }} id={'order-items-card'}>
                        {renderLabelPrintButton()}
                        {usergroups && (usergroups.includes('managers') || usergroups.includes('F001')) && renderEditButtons()}
                        {editable === orderId && !allItemsFinished ?
                            <SelectField
                                label={'shipping_method'}
                                labelHidden={true}
                                size={'small'}
                                defaultValue={orderInfos?.shipping_method ? orderInfos.shipping_method : ShippingMethods.EIGENAUSFAHRT}
                                // @ts-ignore
                                onChange={(e) => setOrderFormData({ ...orderFormData, id: orderInfos.id, shipping_method: e.target.value })} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                            >
                                {Object.values(ShippingMethods).map((method, index) => <option key={index} value={method}>{method}</option>)}
                                {orderInfos?.shipping_method && !Object.values(ShippingMethods).includes(orderInfos.shipping_method) && <option value={orderInfos.shipping_method}>{orderInfos.shipping_method}</option>}
                            </SelectField>
                            :
                            <div style={!finishedOrder && expressOrder ? { color: Color.red, paddingTop: '0.2em' } : { paddingTop: '0.2em' }}>
                                {orderInfos?.shipping_method?.split(':')[0]} : {shippingRevenues.toFixed(2)} €
                            </div>
                        }
                    </div>
                    <div style={{ color: statusColor(orderInfos?.state), display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
                        {orderItems.find(i => !finishedItem(i)) && <div>
                            {orderInfos?.shipping_method?.includes('Abholung') || orderInfos?.shipping_method?.includes('Zuschnittprofi') ?
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        onClick={() => createQRCode(orderItems)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        <IoQrCodeOutline />
                                    </Button>
                                    <Button
                                        variation={'primary'}
                                        size={'small'}
                                        isDisabled={!orderItems.every(i => i.shipping_label_printed === true && (i.status === ProductStatus.ready_to_pack || finishedItem(i)))}
                                        onClick={() => confirmFinishPickUpOrder(orderItems, orderInfos.id)}
                                        loadingText="Auftrag wird fertiggestellt" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                                        Fertigstellung des Auftrags bestätigen
                                    </Button>
                                </div>
                                :
                                <div style={{ display: 'flex', gap: '0.5em' }}>
                                    {orderInfos?.orderNumber.startsWith('R') && !orderItems.find(i => i.productRetoure_shipmentId) && usergroups.includes('managers') &&
                                        <Button
                                            variation={'primary'}
                                            size={'small'}
                                            isDisabled={orderItems.some(i => i.productContractorId !== orderItems[0].productContractorId)}
                                            onClick={() => { createRetoureShipment(orderItems); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                        >
                                            Retourelabel erstellen
                                        </Button>}
                                    {orderItems.every((p: Product) => !p.productShipmentId)
                                        &&
                                        <Button
                                            variation={'primary'}
                                            size={'small'}
                                            isDisabled={filters.contractor === 'Alle'}
                                            onClick={() => { createShipmentForAllItems(orderItems); }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                        >
                                            Sendung erstellen
                                        </Button>}
                                </div>}
                        </div>}
                        {usergroups.includes('managers') && orderItems.find(i => (i.status === ProductStatus.packing_confirmed || i.status === ProductStatus.billed) && !i.reklaOrderNumber) &&
                            <Suspense fallback={'REKLA'}><ReklaButton orderItems={orderItems} otherItemsOfOrder={otherItemsOfOrder} /></Suspense>}
                        <Button
                            id='minimize-button'
                            size='small'
                            onClick={() => setMinimize(true)} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                            <FiMinimize2 />
                        </Button>
                    </div>
                </OrderStatusContainer>
                <OrderInfos>
                    <OrderNumber style={{ flex: 2 }}>
                        <div style={{ fontWeight: 'bold', display: 'flex', gap: '0.2rem', marginTop: '1em' }}>
                            {orderInfos?.state === OrderState.unfinished && orderItems.find(i => i.status === ProductStatus.new) ?
                                <div style={{ display: 'grid', alignItems: 'center' }}>{orderSelected ? <RxCrossCircled onClick={() => dispatch(removeSelectedOrderId(orderId))} /> :
                                    <NumberSelectIcon onClick={() => dispatch(addSelectedOrderId(orderId))} />}
                                </div>
                                :
                                <div>#</div>
                            }
                            {orderInfos?.orderNumber}
                        </div>
                        <div style={{ fontSize: 'small', alignItems: 'center' }}>{orderInfos?.source && orderSourceName(orderInfos.source)}</div>

                        <div style={{ color: Color.darkgrey, display: 'flex', flexDirection: 'row', gap: '0.2em' }}>
                            {orderInfos?.state === OrderState.importing &&
                                <div style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: '0.1em' }}>
                                        <MdOutlineLockClock id='locked-icon' />
                                    </div>
                                    <div>Lade Positionen...</div>
                                </div>
                            }
                        </div>
                        <div style={{ fontSize: 'small' }}>Positionen gesamt: {orderItems.length + otherItemsOfOrder.length}</div>
                        <div style={{ height: 10 }} />
                        <div style={{ display: 'flex' }}>
                            <div>{orderInfos?.customer.first_name} {orderInfos?.customer.last_name}</div>
                            {orderInfos && <AddressPopup order={orderInfos} />}
                        </div>
                        <div style={{ height: 10 }} />
                        <div>
                            <div>zugesagte Lieferung: </div>
                            {orderInfos?.delivery_date && <div>{prettyDate(orderInfos.delivery_date)}</div>}
                        </div>
                        {!finishedOrder && overdue &&
                            <div style={{ color: Color.red }}>überfällig: {overdueDays} {overdueDays === 1 ? 'Tag' : 'Tage'}</div>
                        }
                        {orderInfos && <RetoureShipmentInfos orderItems={orderItems} order={orderInfos} />}
                        {orderInfos && <ShipmentInfos order={orderInfos} />}
                    </OrderNumber>
                    <OrderItems style={{ flex: 15 }}>
                        {orderItems.map((item: Product, index: number) => (
                            <div key={index} id="items-div">
                                <div>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ flex: 1 }}>
                                                <Suspense fallback={<div>Lade Optionen...</div>}><ItemDetails item={item} otherItemsOfOrder={otherItemsOfOrder} disableAllButtons={false} /></Suspense>
                                                <div style={{ display: 'flex', gap: '0.5em' }}>
                                                    {/* cut */}
                                                    <div style={{ flex: 2, color: Color.lightgrey, display: 'grid', alignItems: 'center' }}>
                                                        {editable === orderId && item.status !== ProductStatus.billed && usergroups.includes('managers') ?
                                                            <SelectField
                                                                label={'contractor'}
                                                                labelHidden={true}
                                                                size={'small'}
                                                                defaultValue={item.productContractorId || ''}
                                                                onChange={(e) => dispatch(saveItemUpdate({
                                                                    id: item.id,
                                                                    productContractorId: e.target.value,
                                                                    contractor_status: `${ e.target.value }-${ item.status }`,
                                                                    contractor_currentTask: item.currentTask ? `${ e.target.value }-${ item.currentTask }` : null
                                                                }))}
                                                                style={{ color: Color.lightgrey, borderColor: Color.lightgrey }}
                                                                isDisabled={item.contractor?.number === 'F012' && item.status !== ProductStatus.new} // items for F012 are exported to FG so we don't want it to be changed
                                                                onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}                                                            >
                                                                {contractors.map((possibleContractor) => (
                                                                    <option key={possibleContractor.id} value={possibleContractor.id}>{possibleContractor.name}</option>
                                                                ))}
                                                            </SelectField> :
                                                            <div style={{ fontSize: '0.7vw' }} id='item-contractor-name'>{item.contractor?.name}</div>
                                                        }
                                                    </div>
                                                    <div style={{ flex: 23 }}>
                                                        <Suspense fallback={<div>Lade ToDos...</div>}><ToDoList item={item} /></Suspense>
                                                    </div>
                                                </div>
                                                <div style={{ height: 20 }} />
                                            </div>
                                            {(usergroups.includes('managers') || usergroups.includes('viewPrices')) && <Suspense fallback={<div>Lade Kosten...</div>}><ProductCosts product={item} /></Suspense>}
                                        </div>
                                    </div>
                                </div>
                                {(index + 1 < orderItems.length || otherOngoingItems.length > 0) && <Divider size='small' width={'99%'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                            </div>
                        ))}
                        {otherOngoingItems.map((item: Product, index: number) => (
                            <div key={index} style={{ color: Color.lightgrey }} id="other-ongoing-items-div">
                                <div>
                                    <div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div style={{ flex: 1 }}>
                                                <ItemDetails item={item} otherItemsOfOrder={otherItemsOfOrder} disableAllButtons={true} />
                                                <div style={{ height: 20 }} />
                                            </div>
                                            {(usergroups.includes('managers') || usergroups.includes('viewPrices')) && <ProductCosts product={item} />}
                                        </div>
                                    </div>
                                </div>
                                {index + 1 < otherOngoingItems.length && <Divider size='small' width={'99%'} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
                            </div>
                        ))}
                        {(usergroups.includes('managers') || usergroups.includes('viewPrices')) && orderInfos && <Suspense fallback={<div>Lade Kosten...</div>}><OrderCosts order={orderInfos} /></Suspense>}
                    </OrderItems>
                </OrderInfos >
            </OrderCardDiv >
        </ThemeProvider >
    )
}

export default withAuthenticator(OrderItemsCard)

